import Vue from 'vue'
import VueRouter from 'vue-router'
// 登录
const Login = () => import(/* webpackChunkName: "Login" */ '../views/Login/Login') // 登录
const Registered = () => import(/* webpackChunkName: "Login" */ '../views/Login/Registered') // 注册
const Choose = () => import(/* webpackChunkName: "Choose" */ '../views/Login/Choose') // 注册
// 首页
const Layout = () => import(/* webpackChunkName: "index" */ '../views/Index/Layout') // 布局
const Index = () => import(/* webpackChunkName: "index" */ '../views/Index/index') // 首页
const VideoItem = () => import(/* webpackChunkName: "index" */ '../views/Index/VideoItem') // 视频分类
const VideoCate = () => import(/* webpackChunkName: "index" */ '../views/Index/VideoCate') // 视频分类
const Search = () => import(/* webpackChunkName: "index" */ '../views/Index/Search') // 搜索
// 学习
const learnLesson = () => import(/* webpackChunkName: "learnLesson" */ '../views/learnLesson/learnLesson') // 学习课程
// 我的
const Mine = () => import(/* webpackChunkName: "Mine" */ '../views/Mine/Mine') // 我的
const MyCollection = () => import(/* webpackChunkName: "Mine" */ '../views/Mine/MyCollection') // 我的收藏
const AlreadyBoughtCourse = () => import(/* webpackChunkName: "Mine" */ '../views/Mine/AlreadyBoughtCourse') // 已购课程
const CertificateList = () => import(/* webpackChunkName: "Mine" */ '../views/Mine/CertificateList') // 获得证书
const CertificateInfo = () => import(/* webpackChunkName: "Mine" */ '../views/Mine/CertificateInfo') // 证书详情
const CertificateMail = () => import(/* webpackChunkName: "Mine" */ '../views/Mine/CertificateMail') // 证书邮寄
const MyTest = () => import(/* webpackChunkName: "Mine" */ '../views/Mine/MyTest') // 我的考试
const TestQuestions = () => import(/* webpackChunkName: "Mine" */ '../views/Mine/TestQuestions') // 考试题
const Setting = () => import(/* webpackChunkName: "Mine" */ '../views/Mine/Setting') // 设置
const BuyVip = () => import(/* webpackChunkName: "Mine" */ '../views/Mine/BuyVip') // 设置
const Kaoshi = () => import(/* webpackChunkName: "Mine" */ '../views/Kaoshi') // 设置
Vue.use(VueRouter)
const routes = [
  { path: '/Login', component: Login },
  { path: '/Registered', component: Registered },
  {
    path: '/Layout',
    component: Layout,
    children: [
      { path: '/index', component: Index },
      { path: '/learnLesson', component: learnLesson },
      { path: '/Mine', component: Mine }
    ]
  },
  { path: '/', redirect: '/Login' },
  { path: '/Choose', component: Choose },
  { path: '/Search', component: Search },
  { path: '/MyCollection', component: MyCollection },
  { path: '/AlreadyBoughtCourse', component: AlreadyBoughtCourse },
  { path: '/CertificateList', component: CertificateList },
  { path: '/CertificateInfo/:id', component: CertificateInfo, props: true },
  { path: '/CertificateMail/:id', component: CertificateMail, props: true },
  { path: '/MyTest', component: MyTest },
  { path: '/Setting', component: Setting },
  { path: '/VideoItem/:videoId/:activeVideoId', component: VideoItem, props: true },
  { path: '/VideoCate/:videoCateId', component: VideoCate, props: true },
  { path: '/TestQuestions/:Id', component: TestQuestions, props: true },
  { path: '/BuyVip', component: BuyVip },
  { path: '/Kaoshi', component: Kaoshi }
]
const router = new VueRouter({
  routes,
  base: '/yunketang/'
})
export default router
