import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 导入vant
import Vant, { Lazyload } from 'vant'
// 字体图标
import './assets/font/iconfont.css'
// 全局过滤器
import './utils/filter'
// 导入第三方全局样式
import './assets/styles/normalize.css'
// 导入全局样式
import './assets/styles/reset.less'
// 动态设置REM基准值
import 'amfe-flexible'
// 占位符
import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)
Vue.use(Vant)
Vue.use(Lazyload)
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
